import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import SubmitLOE from "@/views/SubmitLOE.vue";
import Response from "@/views/ResponseLOE.vue";
import UserList from "@/views/UserProjectList.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/submitloe",
    name: "SubmitLOE",
    component: SubmitLOE,
  },
  {
    path: "/ResponseLOE",
    name: "ResponseLOE",
    component: Response,
  },
   {
    path: "/UserProjectList",
    name: "UserProjectList",
     component: UserList,
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;