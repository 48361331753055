<template>
    <img alt="OIT logo" src="./assets/co_oit__dept_spsc_rgb.png" class="logoimg">
    <div id="nav">
        <router-link class="nav-btn" to="/">Home</router-link>
        <router-link class="nav-btn" to="/SubmitLOE">SubmitLOE</router-link>
        <!-- <router-link class="nav-btn" to="/ResponseLOE">ResponseLOE</router-link> -->
        <router-link class="nav-btn" to="/UserProjectList">Project List</router-link>
    </div>
    <router-view />
</template>

<script setup>
 // import { Authenticator } from "@aws-amplify/ui-vue";
 // import "@aws-amplify/ui-vue/styles.css";

 // import Amplify from 'aws-amplify';
  //import awsconfig from './aws-exports';

  //Amplify.configure(awsconfig);
</script>

<script>
import "bootstrap"
//import "bootstrap/dist/css/bootstrap.min.css";

export default {
    name: 'App',
}
</script>

<style>
:root{
    --bg-color: #ffffff;
    --bg-secondary: #2551a3;
    --bg-secondary-lighter: #4e7fda;
    --text-main: #ffffff;
    --text-secondary: #245d38;
    --tertiary-main: #d4d7d7;
    --tertiary-secondary: #7ea79a;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: var(--bg-color);
    color: var(--text-secondary);
    padding-top: 60px;
}

.logoimg{
    width: 800px;
}

#nav{
    padding: 10px;
    margin: 30px 30px 30px 0;
    background-color: var(--bg-secondary);
    width: 100%;
}

.nav-btn{
    color: var(--text-main);
    font-size: 20pt;
    text-decoration: none;
    padding: 4px 20px 4px 20px;
}

.nav-btn:hover{
    color: var(--tertiary-secondary);
    text-decoration: underline;
    /* background-color: var(--tertiary-main); */
}

#submit, #response{
    text-align: left;
    padding-bottom: 60px;
}

label{
    font-size: 16pt;
}

.form-control{
    border: 4px solid var(--bg-secondary);
}

.checkbox-label{
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
}

.checkbox-section{
    margin: 40px 0 40px 0;
}

.checkrow{
    padding: 10px 76px 0 76px;
}

.check{
    text-align: right;
    width: 200px;
    height: 47px;
}

.checkbox{
    margin: 0 20px 0 10px;
    width: 30px;
    height: 30px;
    box-shadow: 2px 1px 2px var(--bg-secondary);
}

.checkbox:checked{
    box-shadow: 2px 2px 8px var(--bg-secondary);
}

#btn-row{
    text-align: center;
}

.btn{
    margin: 10px 20px 10px 20px;
    width: 120px;
    height: 58px;
    font-size: 20pt;
}

.btn-primary, .btn-secondary{
    border: 4px solid var(--tertiary-secondary);
    background-color: var(--bg-secondary);
    color: var(--bg-color);
}

.btn-primary:hover, .btn-secondary:hover{
    border: 4px solid var(--tertiary-secondary);
    background-color: var(--bg-secondary-lighter);
    color: var(--bg-color);
}

.redText{
    color: red;
}
</style>
