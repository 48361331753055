<template>
    <div class="myModal" v-if="showModal">
        <div v-if="showModal" class="modal-content">
            <div class="modal-header" id="modalTitle">
                <!-- <slot name="header"></slot> -->
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal"
                >
                </button>
            </div>
            <hr>
            <div class="modal-body" >
                <!-- {{testData}} -->
                <slot name="body">
                    <div class='modal-title'>
                        <h3>Sweet title goes here</h3>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">ID</label>
                            <input type="text" :id="testData.id" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">LOE</label>
                            <input type="text" :loe="testData.loe" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Reevaluate LOE Date</label>
                            <input type="date" :reevaluateLOEDate="testData.reevaluateLOEDate" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Change Requests</label>
                            <input type="text" :changeRequests="testData.changeRequests" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Project Name/ID</label>
                            <input type="text" :projectName_ID="testData.projectName_ID" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Agency</label>
                            <select :agency="testData.agency" id='agencyDropdown' class="form-select form-control" :disabled="isDisabled">
                            <!-- <option selected disabled value="">Select from dropdown...</option> -->
                            <option v-for="option in agencyDropdownList" :key="option.id" v-bind:value="option.value">
                                {{ option.text}}
                            </option>
                            </select>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Scope of Work</label>
                            <input type="text" :scopeOfWork="testData.scopeOfWork" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Submitted By</label>
                            <input type="date" :submittedBy="testData.submittedBy" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Submitted To</label>
                            <input type="text" :submittedTo="testData.submittedTo" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Risks</label>
                            <input type="text" :risks="testData.risks" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Assumptions</label>
                            <input type="text" :assumptions="testData.assumptions" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Dependencies</label>
                            <input type="text" :dependencies="testData.dependencies" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Exclusions</label>
                            <input type="text" :exclusions="testData.exclusions" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Accepted By</label>
                            <input type="text" :acceptedBy="testData.acceptedBy" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Resources Required</label>
                            <input type="text" :resourcesRequired="testData.resourcesRequired" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Resources Available</label>
                            <input type="text" :resourcesAvailable="testData.resourcesAvailable" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Disclaimer</label>
                            <input type="text" :disclaimer="testData.disclaimer" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Expiration</label>
                            <input type="date" :expiration="testData.expiration" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Requirement Version</label>
                            <input type="text" :requirementVersion="testData.requirementVersion" class="form-control" readonly/>
                        </div>
                        <div class='column'>

                        </div>
                    </div>

                    <div class='modal-row'>
                        <label class="checkbox-label">Types of Estimation:</label>
                        <div class='form-control'>
                            <div class="mb-3 row checkrow">
                                <div class="col check">
                                    <label class="form-label">Effort</label>
                                    <input :typesOfEstimation="testData.typesOfEstimation" :value="1" type="checkbox" class="checkbox" :disabled="isDisabled">
                                </div>
                                <div class="col check">
                                    <label class="form-label">Cost</label>
                                    <input :typesOfEstimation="testData.typesOfEstimation" :value="2" type="checkbox" class="checkbox" :disabled="isDisabled">
                                </div> 
                                <div class="col check">
                                    <label class="form-label">Resource</label>
                                    <input :typesOfEstimation="testData.typesOfEstimation" :value="3" type="checkbox" class="checkbox" :disabled="isDisabled">
                                </div>
                            </div>
                            <div class="mb-3 row checkrow">
                                <div class="col check">
                                    <label class="form-label">Consideration of past history</label>
                                    <input :typesOfEstimation="testData.typesOfEstimation" :value="4" type="checkbox" class="checkbox" :disabled="isDisabled">
                                </div>
                            </div>
                            <div class="mb-3 row checkrow">
                                <div class="col check">
                                    <label class="form-label">A need to understand the overall goal of the project/effort.</label>
                                    <input :typesOfEstimation="testData.typesOfEstimation" :value="5" type="checkbox" class="checkbox" :disabled="isDisabled">
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Anticipated Solution Options</label>
                            <select :anticipatedSolutionOptions="testData.anticipatedSolutionOptions" id="anticipatedSolutionOptionsDropdown"  class='form-select form-control' :disabled="isDisabled">
                                <option v-for="option in anticipatedSolutionDropdownList" :key="option.id" v-bind:value="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                        <div class='column'>
                            <label class="form-label">Defined Project Approach</label>
                            <input type="text" :definedProjectApproach="testData.definedProjectApproach" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Comments</label>
                            <input type="text" :comments="testData.comments" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Agency Staffing Requirements</label>
                            <input type="text" :agencyStaffingRequirements="testData.agencyStaffingRequirements" class="form-control" readonly>
                        </div>
                    </div>
                    <div class='modal-row'>
                        <div class='column'>
                            <label class="form-label">Proposed Start Date</label>
                            <input type="date" :proposedStartDate="testData.proposedStartDate" class="form-control" readonly/>
                        </div>
                        <div class='column'>
                            <label class="form-label">Solution Approach</label>
                            <input type="text" :solutionApproach="testData.solutionApproach" class="form-control" readonly>
                        </div>
                    </div>
                </slot>
            </div>
            <hr>
            <div>
                <button @click='closeModal'>Close</button>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'user-project-two',
    props: {
      showModal: Boolean,
      testData: Object,
      id: String,
      loe: String,
      reevaluateLOEDate: Date,
      changeRequests: String,
      projectName_ID: String,
      agency: Number,
      scopeOfWork: String,
      submittedBy: Date,
      submittedTo: String,
      risks: String,
      assumptions: String,
      depedencies: String,
      exclusions: String,
      signature: String,
      acceptedBy: String,
      resourcesRequired: String,
      resourcesAvailable: String,
      disclaimer: String,
      expiration: Date,
      requirementVersion: String,
      typesOfEstimation: Array,
      anticipatedSolutionOptions: Number,
      definedProjectApproach: String,
      comments: String,
      agencyStaffingRequirements: String,
      proposedStartDate: Date,
      solutionApproach: String
    },
    data(){
        return {
            isDisabled: true,
            proposedRollOutTypeList: [
                { text: 'Big-bang', value: 1 },
                { text: 'Phased', value: 2 }
            ],
            anticipatedSolutionDropdownList: [ // 1 = value passed in response.data
                { text: 'modification or enhancement to existing business application', value: 1 },
                { text: 'upgrade to a new release', value: 2 },
                { text: 'new application', value: 3 }
            ],
            agencyDropdownList: [
                { text: 'Department of Agriculture (CDA)', value: 1 },
                { text: 'Department of Corrections (DOC)', value: 2 },
                { text: 'Department of Education (DOE)', value: 3 },
                { text: 'Department of Health Care Policy & Financing (HCPF)', value: 4 },
                { text: 'Department of Human Services (CDHS)', value: 5 },
                { text: 'Department of Labor and Employment (CDLE)', value: 6 },
                { text: 'Department of Law (DOL)', value: 7 },
                { text: 'Department of Local Affairs (DOLA)', value: 8 },
                { text: 'Department of Military and Veterans Affairs (DMVA)', value: 9 },
                { text: 'Department of Natural Resources (DNR)', value: 10 },
                { text: 'Department of Personnal & Administration (DPA)', value: 11 },
                { text: 'Department of Public Health & Environment (CDPHE)', value: 12 },
                { text: 'Department of Public Safety (CDPS)', value: 13 },
                { text: 'Department of Regulatory Agencies (DORA)', value: 14 },
                { text: 'Department of Revenue (CDOR)', value: 15 },
                { text: 'Department of State (DOS)', value: 16 },
                { text: 'Department of Transportation (CDOT)', value: 17 },
                { text: "Governor's Office of Information Technology", value: 18 }
            ]
        }
    },
    methods: {
        closeModal() {
            this.$emit('clicked');
        }
    }
  };
</script>

<style>
    .form-label{
        float: left;
    }

    .myModal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 100%;
        height: 80%;
        max-height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px #a7a7a7;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    .modal-title h3 {
        margin: 0 0 20px 0;
        font-size: 30pt;
        color: #42b983;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .modal-body {
        margin: 20px 0;
        height: 80%;
        overflow-y: auto;
    }

        .modal-row{
            display: inline-block;
        }

        .column{
            width: 500px;
            display: inline-block;
            margin: 12px;
            vertical-align: top;
        }

    .modal-default-button {
        display: block;
        margin-top: 1rem;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter-active,
    .modal-leave-active {
        transition: opacity 0.5s ease;
    }

    .modal-enter-from,
    .modal-leave-to {
        opacity: 0;
    }
</style>
