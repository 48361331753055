import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AmplifyVue from '@aws-amplify/ui-vue';

const app = createApp(App);
app.use(router);
//app.component(UserProject);
app.use(AmplifyVue);
app.mount('#app');

//createApp(App).use(router).mount('#app')
