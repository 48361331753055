<template>
<div class="container">
    <div id="userProjectList" class="row"></div>
    <div class="row">

        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>View</th>
                    <th>projectName</th>
                    <th>description</th>
                    <th>requestedDate</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="data in dataArray" :key="data.id" type="" class="">
                    <td><button type="button" @click="openModal(data)" 
                    class="btn btn-primary" aria-label="View/Edit Button" title="View Button">
                        View
                    </button></td>
                    <td>{{data.projectName}}</td>
                    <td>{{data.description}}</td>
                    <td>{{data.requestedDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <user-project-two v-if="showModal" :showModal=showModal :testData=testData @clicked="onChildClick" />
        

</div>


    

<!-- <UserProjectTwo v-show="isModalVisible" @close="closeModal" v-bind:site="dataArray" ref="UserProject" /> -->

</template>

<script>
    import { ref } from 'vue';
    // import UserProject from "@/components/UserProject.vue";
    import UserProjectTwo from "@/components/UserProjectTwo.vue";
    import "jquery";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "@popperjs/core/lib/popper.js";
    import "bootstrap/dist/js/bootstrap.min.js";
    //  import EditSitesModal from "@/components/EditSitesModal";
//v-bind:alt="propsId(dataArray)" 
//@click="(e) => $refs.UserProject.openModal(dataArray)"
//ref="UserProject"

    export default{
        name: "userProjectListPage",
        components: {
            UserProjectTwo
        },
        data() {
            return{
                showModal: false,
                testData: ''
            }
        },
        setup() {
             const dataArray = ref([
            {  projectName: 'project name HARDCODE',
                requestedDateTime: '2021-10-26T23:51:00Z',
                description: 'description HARDCODE',
                proposedRollOutType: 'proposed rollout type HARDCODE',
                requestedDate: '2021-10-25T23:51:00Z',
                anticipatedSolutionOptionsDropdown: '1',
                estimatedDelivery: '2021-10-27T00:00:00Z',
                successCriteria: 'success criteria HARDCODE',
                fileUpload: 'file upload HARDCODE',
                businessOwnerName: 'business owner name HARDCODE',
                fundingAgencySource: 'funding agency source HARDCODE',
                agencySponsor: 'agency sponsor HARDCODE',
                requestorName: 'requestor name HARDCODE',
                requestorEmail: 'requestor email HARDCODE',
                otherImpactedAgency: 'other impacted agency HARDCODE',
                impactingStakeholders: 'impacting stakeholders HARDCODE',
                highLevelOverview: 'high level overview HARDCODE',
                businessProblemStatment: 'business problem statement HARDCODE',
                dataRequirements: 'data requirements HARDCODE',
                reportingNeeds: 'reporting needs HARDCODE',
                agencyDropdown: '2',
                showSuccess: false,
                successMsg: '',
                SME: '',
                productOwnerPercentAllocation: '',
                smePercentAllocation: '',
                valueDelivered: '',
                requestedRespondBy: '',
                purpose: 'true purpose',
                checkboxes: []},
                     {  projectName: 'project name HARDCODE',
                requestedDateTime: '2021-10-26T23:51:00Z',
                description: 'description HARDCODE',
                proposedRollOutType: 'proposed rollout type HARDCODE',
                requestedDate: '2021-10-25T23:51:00Z',
                anticipatedSolutionOptionsDropdown: '1',
                estimatedDelivery: '2021-10-27T00:00:00Z',
                successCriteria: 'success criteria HARDCODE',
                fileUpload: 'file upload HARDCODE',
                businessOwnerName: 'business owner name HARDCODE',
                fundingAgencySource: 'funding agency source HARDCODE',
                agencySponsor: 'agency sponsor HARDCODE',
                requestorName: 'requestor name HARDCODE',
                requestorEmail: 'requestor email HARDCODE',
                otherImpactedAgency: 'other impacted agency HARDCODE',
                impactingStakeholders: 'impacting stakeholders HARDCODE',
                highLevelOverview: 'high level overview HARDCODE',
                businessProblemStatment: 'business problem statement HARDCODE',
                dataRequirements: 'data requirements HARDCODE',
                reportingNeeds: 'reporting needs HARDCODE',
                agencyDropdown: '2',
                showSuccess: false,
                successMsg: '',
                SME: '',
                productOwnerPercentAllocation: '',
                smePercentAllocation: '',
                valueDelivered: '',
                requestedRespondBy: '',
                purpose: 'true purpose',
                checkboxes: []},
                     {  projectName: 'project name HARDCODE',
                requestedDateTime: '2021-10-26T23:51:00Z',
                description: 'description HARDCODE',
                proposedRollOutType: 'proposed rollout type HARDCODE',
                requestedDate: '2021-10-25T23:51:00Z',
                anticipatedSolutionOptionsDropdown: '1',
                estimatedDelivery: '2021-10-27T00:00:00Z',
                successCriteria: 'success criteria HARDCODE',
                fileUpload: 'file upload HARDCODE',
                businessOwnerName: 'business owner name HARDCODE',
                fundingAgencySource: 'funding agency source HARDCODE',
                agencySponsor: 'agency sponsor HARDCODE',
                requestorName: 'requestor name HARDCODE',
                requestorEmail: 'requestor email HARDCODE',
                otherImpactedAgency: 'other impacted agency HARDCODE',
                impactingStakeholders: 'impacting stakeholders HARDCODE',
                highLevelOverview: 'high level overview HARDCODE',
                businessProblemStatment: 'business problem statement HARDCODE',
                dataRequirements: 'data requirements HARDCODE',
                reportingNeeds: 'reporting needs HARDCODE',
                agencyDropdown: '2',
                showSuccess: false,
                successMsg: '',
                SME: '',
                productOwnerPercentAllocation: '',
                smePercentAllocation: '',
                valueDelivered: '',
                requestedRespondBy: '',
                purpose: 'true purpose',
                checkboxes: []}
                    // { firstName: 'Vic', lastName: 'Reynolds', email: 'vic.reynolds@test.com', role: 'Admin' },
                    // { firstName: 'Gina', lastName: 'Jabowski', email: 'gina.jabowski@test.com', role: 'Admin' },
                    // { firstName: 'Jessi', lastName: 'Glaser', email: 'jessi.glaser@test.com', role: 'User' },
                    // { firstName: 'Jay', lastName: 'Bilzerian', email: 'jay.bilzerian@test.com', role: 'User' }
                ]);
                return {
                    dataArray,
                  //  visible : false
                };
        },
        methods: {
            openModal(projectData) {
                console.log('projectData = ' + JSON.stringify(projectData));
                console.log(" this.isModalVisible = true");
                this.showModal = true;
                this.testData = projectData;
                // Event.$emit('showModal', projectData);
                console.log(" this.isModalVisible: ", this.showModal);
            },
            onChildClick() {
                this.showModal = false;
            }
        //     showModal() {
        //         console.log(" this.isModalVisible = true");
        //         this.showModal = true;
        //         // Event.$emit('showModal'); // , projectData
        //         console.log(" this.isModalVisible: ", this.showModal);
        //     },
        //     closeModal() {
        //            console.log("this.isModalVisible = false;");
        //         this.showModal = false;
        //           console.log(" this.isModalVisible: ", this.showModal);
        //     }
        }
        // //   data: () => ({
        // //         visible: false,
        // //         isModalVisible: false
        // //     }),
     
    };

    
    //  var dataArray = {
    //     projectName: 'project name HARDCODE',
    //     requestedDateTime: '2021-10-26T23:51:00Z',
    //     description: 'description HARDCODE',
    //     proposedRollOutType: 'proposed rollout type HARDCODE',
    //     requestedDate: '2021-10-25T23:51:00Z',
    //     anticipatedSolutionOptionsDropdown: '1',
    //     estimatedDelivery: '2021-10-27T00:00:00Z',
    //     successCriteria: 'success criteria HARDCODE',
    //     fileUpload: 'file upload HARDCODE',
    //     businessOwnerName: 'business owner name HARDCODE',
    //     fundingAgencySource: 'funding agency source HARDCODE',
    //     agencySponsor: 'agency sponsor HARDCODE',
    //     requestorName: 'requestor name HARDCODE',
    //     requestorEmail: 'requestor email HARDCODE',
    //     otherImpactedAgency: 'other impacted agency HARDCODE',
    //     impactingStakeholders: 'impacting stakeholders HARDCODE',
    //     highLevelOverview: 'high level overview HARDCODE',
    //     businessProblemStatment: 'business problem statement HARDCODE',
    //     dataRequirements: 'data requirements HARDCODE',
    //     reportingNeeds: 'reporting needs HARDCODE',
    //     agencyDropdown: '2',
    //     // checkboxes: [3,4],
    //     showSuccess: false,
    //     successMsg: '',
    //     SME: '',
    //     productOwnerPercentAllocation: '',
    //     smePercentAllocation: '',
    //     valueDelivered: '',
    //     requestedRespondBy: '',
    //     purpose: 'true purpose',
    //     checkboxes: [],
    // }

    // console.log("dataArray: ",dataArray);

</script>

