<template>
<div class="container">
    <div ref="elRef" class="row"></div>
    <div class="row">
        <!-- <h1>Submit</h1> -->
        <div id="submit">
            <form id="submit-form" @submit.prevent="onSubmit">
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Project Name</label>
                        <input type="text" v-model="projectName" class="form-control" required />
                        <span v-if="msg.projectName" class="redText">{{msg.projectName}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Description</label>
                        <input type="text" v-model="description" class="form-control">
                        <span v-if='msg.description' class='redText'>{{msg.description}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Estimated Response By</label>
                        <input type="date" v-model="estimatedResponseBy" class="form-control" readonly>
                        <span v-if="msg.estimatedResponseBy" class="redText">{{msg.estimatedResponseBy}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Requested Date</label>
                        <input type="date" v-model="requestedDate" class="form-control" readonly>
                        <span v-if="msg.requestedDate" class="redText">{{msg.requestedDate}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <div class="form-group">
                        <label class="form-label">Proposed RollOut Type</label>
                            <select v-model.number="proposedRollOutType" class="form-control">
                                <!-- <option selected disabled value="">Select from dropdown...</option> -->
                                <option v-for="option in proposedRollOutType" :key="option.value" :value="option.value">
                                    {{ option.text}}
                                </option>
                            </select>
                            <span v-if="msg.proposedRollOutType" class="redText">{{msg.proposedRollOutType}}</span>
                        </div>
                    </div>
                    <div class="col">
                        <label class="form-label">Estimated Delivery Due Date</label>
                        <input type="date" v-model="estimatedDelivery" class="form-control">
                        <span v-if="msg.estimatedDelivery" class="redText">{{msg.estimatedDelivery}}</span>
                    </div>                
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <div class="form-group">
                        <label class="form-label">Anticipated Solution Options</label>
                            <select v-model.number="anticipatedSolutionOptions" class="form-control">
                                <!-- <option selected disabled value="">Select from dropdown...</option> -->
                                <option v-for="option in anticipatedSolutionOptionsDropdown" :key="option.value" :value="option.value">
                                    {{ option.text}}
                                </option>
                            </select>
                            <span v-if="msg.anticipatedSolutionOptions" class="redText">{{msg.anticipatedSolutionOptions}}</span>
                        </div>
                    </div>
                    <div class="col">
                        <label class="form-label">Success Criteria:</label>
                        <textarea class="form-control"  v-model="successCriteria"></textarea>
                        <span v-if="msg.successCriteria" class="redText">{{msg.successCriteria}}</span>
                    </div> 
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Business Product Owner</label>
                        <input type="text" v-model="businessProductOwner" class="form-control">
                        <span v-if="msg.businessProductOwner" class="redText">{{msg.businessProductOwner}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Agency</label>
                        <select v-model.number="agency" class="form-control">
                                <!-- <option selected disabled value="">Select from dropdown...</option> -->
                                <option v-for="option in agencyDropdown" :key="option.value" :value="option.value">
                                    {{ option.text}}
                                </option>
                            </select>
                        <span v-if="msg.agency" class="redText">{{msg.agency}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Funding Agency or Source</label>
                        <input type="text" v-model="fundingAgencySource" class="form-control">
                        <span v-if="msg.fundingAgencySource" class="redText">{{msg.fundingAgencySource}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Business Sponsor</label>
                        <input type="text" v-model="businessSponsor" class="form-control">
                        <span v-if="msg.businessSponsor" class="redText">{{msg.businessSponsor}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Requester Name</label>
                        <input type="text" v-model="requestorName" class="form-control">
                        <span v-if="msg.requestorName" class="redText">{{msg.requestorName}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Requester Email</label>
                        <input type="text"  v-model="requestorEmail" class="form-control">
                        <span v-if="msg.requestorEmail" class="redText">{{msg.requestorEmail}}</span>
                    </div> 
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Other Impacted Agencies/Divisions (stakeholders)</label>
                        <textarea v-model="otherImpactedAgency" class="form-control" />
                        <span v-if="msg.otherImpactedAgency" class="redText">{{msg.otherImpactedAgency}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">High Level Overview</label>
                        <textarea v-model="highLevelOverview" class="form-control"></textarea>
                        <span v-if="msg.highLevelOverview" class="redText">{{msg.highLevelOverview}}</span>
                    </div>
                    <!-- <div class="col">
                        <label class="form-label">Impacting Stakeholders</label>
                        <textarea v-model="impactingStakeholders" class="form-control"></textarea>
                        <span v-if="msg.impactingStakeholders" class="redText">{{msg.impactingStakeholders}}</span>
                    </div> -->
                </div>

                <div class="">
                    <div class="">
                        <check-box fieldId="Luther" v-model:names="names" value="Luther" />
                        <check-box fieldId="Diego" v-model:names="names" value="Diego" />
                        <check-box fieldId="Allison" v-model:names="names" value="Allison" />
                        <check-box fieldId="Klaus" v-model:names="names" value="Klaus" />
                        <check-box fieldId="Five" v-model:names="names" value="Five" />
                        <check-box fieldId="Ben" v-model:names="names" value="Ben" />
                        <check-box fieldId="Vanya" v-model:names="names" value="Vanya" />
                    </div>
                    <div class="">{{ names }}</div>
                </div>
                
                <div class='checkbox-section'>
                    <label class="checkbox-label">Justification/Value Delivered:</label>
                    <div class='form-control'>
                        <div class="mb-3 row checkrow">
                            <div class="col check">
                                <label class="form-label">Ease of Use</label>
                                <input v-model="checkboxes" :value="1" type="checkbox" class="checkbox">
                            </div>
                            <div class="col check">
                                <label class="form-label">Simplification</label>
                                <input v-model="checkboxes" :value="2" type="checkbox" class="checkbox">
                            </div> 
                            <div class="col check">
                                <label class="form-label">Response Time</label>
                                <input v-model="checkboxes" :value="3" type="checkbox" class="checkbox">
                            </div>
                            <div class="col check">
                                <label class="form-label">Saves Time</label>
                                <input v-model="checkboxes" :value="4" type="checkbox" class="checkbox">
                            </div>
                        </div>
                        <div class="mb-3 row checkrow">
                            <div class="col check">
                                <label class="form-label">Training</label>
                                <input v-model="checkboxes" :value="5" type="checkbox" class="checkbox">
                            </div>
                            <div class="col check">
                                <label class="form-label">Compliance</label>
                                <input v-model="checkboxes" :value="6" type="checkbox" class="checkbox">
                            </div> 
                            <div class="col check">
                                <label class="form-label">Data Management</label>
                                <input v-model="checkboxes" :value="7" type="checkbox" class="checkbox">
                            </div>
                            <div class="col check">
                                <label class="form-label">Pain Point</label>
                                <input v-model="checkboxes" :value="8" type="checkbox" class="checkbox">
                            </div>
                        </div>
                        <div class="mb-3 row checkrow">
                            <div class="col check">
                                <label class="form-label">Security Risk</label>
                                <input v-model="checkboxes" :value="9" type="checkbox" class="checkbox">
                            </div>
                            <div class="col check">
                                <label class="form-label">Cost Reduction</label>
                                <input v-model="checkboxes" :value="10" type="checkbox" class="checkbox">
                            </div>
                            <div class="col check">
                                <label class="form-label">Automation</label>
                                <input v-model="checkboxes" :value="11" type="checkbox" class="checkbox">
                            </div>
                            <div class="col check">
                                <label class="form-label">Remain</label>
                                <input v-model="checkboxes" :value="12" type="checkbox" class="checkbox">
                            </div>   
                        </div>
                    </div>
                    <span v-if="msg.checkboxes" class="redText">{{msg.checkboxes}}</span>
                </div>
                
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Business Problem Statement</label>
                        <textarea v-model="businessProblemStatment" class="form-control"></textarea>
                        <span v-if="msg.businessProblemStatment" class="redText">{{msg.businessProblemStatment}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Data Requirements</label>
                        <textarea v-model="dataRequirements" class="form-control"></textarea>
                        <span v-if="msg.dataRequirements" class="redText">{{msg.dataRequirements}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Reporting Needs</label>
                        <textarea  v-model="reportingNeeds" class="form-control"></textarea>
                        <span v-if="msg.reportingNeeds" class="redText">{{msg.reportingNeeds}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">File Upload</label>
                        <input v-model="fileUpload" class="form-control">
                        <div>(pdf, word, google sheet, txt, html, pictures)</div>
                        <span v-if="msg.fileUpload" class="redText">{{msg.fileUpload}}</span>
                    </div>
                </div>
                

                <div class="mb-3 row" id="btn-row">
                    <div class="col">
                        <input type="button" value="Submit" @click="validateEverything" class="btn btn-primary">
                        <input type="button" value="Cancel" class="btn btn-secondary">
                    </div>
                </div>
                <div class="row">
                    <transition name="fade">
                        <p v-if="showSuccess" class="success" style="float:top; background-color:green;padding:10px;color:white;">Your project was submitted successfully. {{this.successMsg}} is the azure id associated with your submission.</p>
                    </transition>
                </div>
            </form>
        </div>
    </div>
</div>

</template>

<script>
    import axios from 'axios'
    export default{
        data() {
            return{
                projectName: 'project name TEST',
                estimatedResponseBy: this.dateFunc(),
                proposedRollOutType: [
                    { text: 'Big-bang', value: 1 },
                    { text: 'Phased', value: 2 }
                ],
                requestedDate: '',
                anticipatedSolutionOptionsDropdown: [
                    { text: 'modification or enhancement to existing business application', value: 1 },
                    { text: 'upgrade to a new release', value: 2 },
                    { text: 'new application', value: 3 }
                ],
                estimatedDelivery: '2021-11-13',
                successCriteria: 'success criteria TEST',
                description: 'description TEST',
                showSuccess: false,
                successMsg: '',
                fileUpload: 'file upload TEST',
                businessProductOwner: 'business owner TEST',
                fundingAgencySource: 'funding agency source TEST',
                businessSponsor: 'agency sponsor TEST',
                requestorName: 'requestor name TEST',
                requestorEmail: 'requestor email TEST',
                otherImpactedAgency: 'other impacted agency TEST',
                impactingStakeholders: 'impacting stakeholders TEST',
                highLevelOverview: 'high level overview TEST',
                businessProblemStatment: 'business problem statement TEST',
                dataRequirements: 'data requirements TEST',
                reportingNeeds: 'reporting needs TEST',
                agencyDropdown: [
                    { text: 'Department of Agriculture (CDA)', value: 1 },
                    { text: 'Department of Corrections (DOC)', value: 2 },
                    { text: 'Department of Education (DOE)', value: 3 },
                    { text: 'Department of Health Care Policy & Financing (HCPF)', value: 4 },
                    { text: 'Department of Human Services (CDHS)', value: 5 },
                    { text: 'Department of Labor and Employment (CDLE)', value: 6 },
                    { text: 'Department of Law (DOL)', value: 7 },
                    { text: 'Department of Local Affairs (DOLA)', value: 8 },
                    { text: 'Department of Military and Veterans Affairs (DMVA)', value: 9 },
                    { text: 'Department of Natural Resources (DNR)', value: 10 },
                    { text: 'Department of Personnal & Administration (DPA)', value: 11 },
                    { text: 'Department of Public Health & Environment (CDPHE)', value: 12 },
                    { text: 'Department of Public Safety (CDPS)', value: 13 },
                    { text: 'Department of Regulatory Agencies (DORA)', value: 14 },
                    { text: 'Department of Revenue (CDOR)', value: 15 },
                    { text: 'Department of State (DOS)', value: 16 },
                    { text: 'Department of Transportation (CDOT)', value: 17 },
                    { text: "Governor's Office of Information Technology", value: 18 }
                ],
                SME: '',
                productOwnerPercentAllocation: '',
                smePercentAllocation: '',
                valueDelivered: '',
                requestedRespondBy: '',
                purpose: 'true purpose',
                checkboxes: [3,4],
                errors: [],
                valPass: true,
                msg: {
                    projectName: '',
                    estimatedResponseBy: '',
                    proposedRollOutType: '',
                    requestedDate: '',
                    anticipatedSolutionOptions: '',
                    estimatedDelivery: '',
                    successCriteria: '',
                    description: '',
                    fileUpload: '',
                    businessProductOwner: '',
                    fundingAgencySource: '',
                    businessSponsor: '',
                    requestorName: '',
                    requestorEmail: '',
                    otherImpactedAgency: '',
                    impactingStakeholders: '',
                    highLevelOverview: '',
                    businessProblemStatment: '',
                    dataRequirements: '',
                    reportingNeeds: '',
                    agency: ''
                }
            };
        },
        methods: {
            dateFunc() {
                // let day = Date.getDay();
                // let month = Date.getMonth();
                // let year = Date.getFullYear();
                // let date = new Date(this.requestedDate); // Thu Nov 11 2021 17:00:00 GMT-0700 (Mountain Standard Time)
                // let estimatedResponseByDate = year + '-' + month + '-' + day;
                let estimatedResponseByDate = new Date();
                console.log("estimatedResponseByDate === " + this.estimatedResponseByDate);
                return estimatedResponseByDate;
            },
            timeOutMessage(azureId) {
                console.log("azureId: ", azureId);
                console.log("inside timeOutmessage!");
                  this.showSuccess = true;

                        setTimeout(() => {
                        this.showSuccess = false
                        }, 10000);

            },  
            redirectTimeOut() {
                    setTimeout(() => {
                                window.location.href = '/index.html';
                                    }, 8000);
            },
            validateProjectName() {
                if (this.projectName === '') {
                    this.msg.projectName = 'Project Name required'
                    this.valPass = false
                    console.log("Project Name didn't pass");
                } else {
                    this.msg.projectName = ''
                }
            },
            validateEstimatedResponseBy(){
                if (this.estimatedResponseBy.length <= 0) {
                    this.msg.estimatedResponseBy = 'Response By required'
                    this.valPass = false
                    console.log("Response By didn't pass");

                } else {
                    this.msg.estimatedResponseBy = ''
                }
            },
            validateDescription() {
                if (this.description === '') {
                    this.msg.description = 'Description required'
                    this.valPass = false
                    console.log("Description didn't pass");
                } else {
                    this.msg.description = ''
                }
            },
            validateProposedRollOutType() {
                if (this.proposedRollOutType === '') {
                    this.msg.proposedRollOutType = 'Proposed Rollout Type required'
                    this.valPass = false
                    console.log("Proposed Rollout Type didn't pass");
                } else {
                    this.msg.proposedRollOutType = ''
                }
            },
            validateRequestedDate(){
                
                if (this.requestedDate.length <= 0) {
                    this.msg.requestedDate = 'Requested Date required'
                    this.valPass = false
                    console.log("Requested Date didn't pass");
                } else {
                    this.msg.requestedDate = ''
                }
            },
            validateAnticipatedSolutionOptions() {
                if (!this.anticipatedSolutionOptions) {
                    this.msg.anticipatedSolutionOptions = 'Anticipated Solution Options required'
                    this.valPass = false
                    console.log("Anticipated Solution Options didn't pass");
                } else {
                    this.msg.anticipatedSolutionOptions = ''
                }
            },
            validateEstimatedDelivery() {
                if (this.estimatedDelivery.length <= 0) {
                    this.msg.estimatedDelivery = 'Estimated Delivery required'
                    this.valPass = false
                    console.log("Estimated Delivery didn't pass");
                } else {
                    this.msg.estimatedDelivery = ''
                }
            },
            validateFileUpload() {
                if (this.fileUpload === '') {
                    this.msg.fileUpload = 'File Upload required'
                    this.valPass = false
                } else {
                    this.msg.fileUpload = ''
                }
            },
            validateSuccessCriteria() {
                if (this.successCriteria === '') {
                    this.msg.successCriteria = 'Success Criteria required'
                    this.valPass = false
                    console.log("Success Criteria didn't pass");
                } else {
                    this.msg.successCriteria = ''
                }
            },
            validateBusinessProductOwner() {
                if (this.businessProductOwner === '') {
                    this.msg.businessProductOwner = 'Business Owner Name required'
                    this.valPass = false
                    console.log("Business Owner Name didn't pass");
                } else {
                    this.msg.businessProductOwner = ''
                }
            },
            validateAgency() {
                if (!this.agency) {
                    this.msg.agency = 'Agency required'
                    this.valPass = false
                    console.log("Agency didn't pass");
                } else {
                    this.msg.agency = ''
                }
            },
            validateFundingAgencySource() {
                if (this.fundingAgencySource === '') {
                    this.msg.fundingAgencySource = 'Funding Agency or Source required'
                    this.valPass = false
                    console.log("Funding Agency Source didn't pass");
                } else {
                    this.msg.fundingAgencySource = ''
                }
            },
            validateBusinessSponsor() {
                if (this.businessSponsor === '') {
                    this.msg.businessSponsor = 'Agency Sponser required'
                    this.valPass = false
                    console.log("Agency Sponsor didn't pass");
                } else {
                    this.msg.businessSponsor = ''
                }
            },
            validateRequestorName() {
                if (this.requestorName === '') {
                    this.msg.requestorName = 'Requestor Name required'
                    this.valPass = false
                    console.log("Requestor Name didn't pass");
                } else {
                    this.msg.requestorName = ''
                }
            },
            validateRequestorEmail() {
                if (this.requestorEmail === '') {
                    this.msg.requestorEmail = 'Requestor Email required'
                    this.valPass = false
                    console.log("Requestor Email didn't pass");
                } else {
                    this.msg.requestorEmail = ''
                }
            },
            validateOtherImpactedAgency() {
                if (this.otherImpactedAgency === '') {
                    this.msg.otherImpactedAgency = 'Other Impacted Agency required'
                    this.valPass = false
                    console.log("Other Impacted Agency didn't pass");
                } else {
                    this.msg.otherImpactedAgency = ''
                }
            },
            validateImpactingStakeholders() {
                if (this.impactingStakeholders === '') {
                    this.msg.impactingStakeholders = 'Impacting Stakeholders required'
                    this.valPass = false
                    console.log("Impacting Stakeholders didn't pass");
                } else {
                    this.msg.impactingStakeholders = ''
                }
            },
            validateCheckBoxes() {
                // var length = this.checkboxes.length;
                // console.log('checkboxes array length = ' + length);
                // console.log('checkboxes = ' + this.checkboxes);
                if (this.checkboxes.length <= 0) {
                    this.msg.checkboxes = 'At least one checkbox must be checked'
                    this.valPass = false
                    console.log("Checkboxes didn't pass");
                } else {
                    this.msg.checkboxes = ''
                }
            },
            validateHighLevelOverview() {
                if (this.highLevelOverview === '') {
                    this.msg.highLevelOverview = 'High Level Overview required'
                    this.valPass = false
                    console.log("High Level Overview didn't pass");
                } else {
                    this.msg.highLevelOverview = ''
                }
            },
            validateBusinessProblemStatment() {
                if (this.businessProblemStatment === '') {
                    this.msg.businessProblemStatment = 'Business Problem Statement required'
                    this.valPass = false
                    console.log("Business Problem Statement didn't pass");
                } else {
                    this.msg.businessProblemStatment = ''
                }
            },
            validateDataRequirements() {
                if (this.dataRequirements === '') {
                    this.msg.dataRequirements = 'Date Requirements required'
                    this.valPass = false
                    console.log("Data Requirements didn't pass");
                } else {
                    this.msg.dataRequirements = ''
                }
            },
            validateReportingNeeds() {
                if (this.reportingNeeds === '') {
                    this.msg.reportingNeeds = 'Reporting Needs required'
                    this.valPass = false
                    console.log("Reporting Needs didn't pass");
                } else {
                    this.msg.reportingNeeds = ''
                }
            },
            validateEverything() {
                console.log("validating everything");
                this.validateProjectName();
                this.validateEstimatedResponseBy();
                console.log('response by = ' + this.estimatedResponseBy);
                this.validateDescription();
                this.validateProposedRollOutType();
                this.validateRequestedDate();
                this.validateAnticipatedSolutionOptions();
                console.log('dropdown = ' + this.anticipatedSolutionOptionsDropdown)
                this.validateEstimatedDelivery();
              //  this.validateFileUpload();
                this.validateSuccessCriteria();
                this.validateBusinessProductOwner();
                this.validateAgency(); 
                this.validateFundingAgencySource();
                this.validateBusinessSponsor();
                this.validateRequestorName();
                this.validateRequestorEmail();
                this.validateOtherImpactedAgency();
                this.validateImpactingStakeholders();
                this.validateCheckBoxes();
                this.validateHighLevelOverview();
                this.validateBusinessProblemStatment();
                this.validateDataRequirements();
                this.validateReportingNeeds();
                if (this.valPass === false) {
                    console.log("valPass did not pass.");
                    this.valPass = true
                } else {
                    this.postPost();
                }
            },
    
            postPost(event) {
            console.log("in postpost");
            console.log("event: ",event );
                var obj = [
                {
                    op: "add",
                    path: "/fields/System.Title",
                    from: null,
                    value: this.projectName
                },
                {
                    op: "add",
                    path: "/fields/System.Description",
                    from: null,
                    value: this.description
                },  
                {
                    op: "add",
                    path: "/fields/Custom.Proposedrollouttype",
                    from: null,
                    value: this.proposedRollOutType
                },
                {
                    op: "add",
                    path: "/fields/Custom.Anticipatedsolutionoptions",
                    from: null,
                    value: this.anticipatedSolutionOptions
                },
                {
                    op: "add",
                    path: "/fields/Custom.SuccessCriteria",
                    from: null,
                    value: this.successCriteria
                },
                {
                    op: "add",
                    path: "/fields/Custom.RequestedRespondBy",
                    from: null,
                    value: this.requestedRespondBy
                },
                {
                    op: "add",
                    path: "/fields/Custom.RequestedDatr",
                    from: null,
                    value: this.requestedDate
                },
                {
                    op: "add",
                    path: "/fields/Custom.EstimatedSolutionDueDate",
                    from: null,
                    value: this.estimatedDelivery
                },
                {
                    op: "add",
                    path: "/fields/Custom.BusinessProductOwner",
                    from: null,
                    value: this.businessProductOwner
                },
                {
                    op: "add",
                    path: "/fields/Custom.ProductOwnerpercentallocation",
                    from: null,
                    value: this.productOwnerPercentAllocation
                },
                {
                    op: "add",
                    path: "/fields/Custom.SubjectMatterExpert",
                    from: null,
                    value: this.SME
                },
                {
                    op: "add",
                    path: "/fields/Custom.SMEpercentallocation",
                    from: null,
                    value: this.smePercentAllocation
                },
                {
                    op: "add",
                    path: "/fields/Custom.FundingAgencyorSource",
                    from: null,
                    value: this.fundingAgencySource
                },
                {
                    op: "add",
                    path: "/fields/Custom.Agency",
                    from: null,
                    value: this.agency
                },
                {
                    op: "add",
                    path: "/fields/Custom.BusinessSponsor",
                    from: null,
                    value: this.businessSponsor
                },
                {
                    op: "add",
                    path: "/fields/Custom.RequesterName",
                    from: null,
                    value: this.requesterName
                },
                {
                    op: "add",
                    path: "/fields/Custom.Requestor",
                    from: null,
                    value: this.requesterEmail
                },
                {
                    op: "add",
                    path: "/fields/Custom.OtherImpactedAgency",
                    from: null,
                    value: this.otherImpactedAgency
                },
                {
                    op: "add",
                    path: "/fields/Custom.ImpactingStakeholders",
                    from: null,
                    value: this.impactingStakeholders
                },
                {
                    op: "add",
                    path: "/fields/Custom.HighLevelOverview",
                    from: null,
                    value: this.highLevelOverview
                },
                {
                    op: "add",
                    path: "/fields/Custom.Businessproblemstatement",
                    from: null,
                    value: this.businessProblemStatment
                },
                {
                    op: "add",
                    path: "/fields/Custom.DataRequirements",
                    from: null,
                    value: this.dataRequirements
                },
                {
                    op: "add",
                    path: "/fields/Microsoft.VSTS.CMMI.Purpose",
                    from: null,
                    value: this.reportingNeeds
                },
                {
                    op: "add",
                    path: "/fields/Custom.ValueDelivered",
                    from: null,
                    value: this.valueDelivered
                }
            ];

            var objStr = [
            {
                "op": "add",
                "path": "/fields/System.Title",
                "from": null,
                "value": this.projectName
            },
            {
                "op": "add",
                "path": "/fields/System.Description",
                "from": null,
                "value": this.description
            },  
            {
                "op": "add",
                "path": "/fields/Custom.Proposedrollouttype",
                "from": null,
                "value": this.proposedRollOutType
            },
            {
                "op": "add",
                "path": "/fields/Custom.Anticipatedsolutionoptions",
                "from": null,
                "value": this.anticipatedSolutionOptions
            },
            {
                "op": "add",
                "path": "/fields/Custom.RequestedRespondBy",
                "from": null,
                "value": this.requestedRespondBy
            },
            {
                "op": "add",
                "path": "/fields/Custom.BusinessProductOwner",
                "from": null,
                "value": this.businessProductOwner
            },
            {
                "op": "add",
                "path": "/fields/Custom.ProductOwnerpercentallocation",
                "from": null,
                "value": this.productOwnerPercentAllocation
            },  
            {
                "op": "add",
                "path": "/fields/Custom.SMEpercentallocation",
                "from": null,
                "value": this.smePercentAllocation
            },
            {
                "op": "add",
                "path": "/fields/Custom.RequestedDatr",
                "from": null,
                "value": this.estimatedResponseBy
            },
            {
                "op": "add",
                "path": "/fields/Custom.Agency",
                "from": null,
                "value": this.agency
            },
            {
                "op": "add",
                "path": "/fields/Custom.EstimatedSolutionDueDate",
                "from": null,
                "value": this.estimatedDelivery
            },
            {
                "op": "add",
                "path": "/fields/Custom.OtherImpactedAgency",
                "from": null,
                "value": this.otherImpactedAgency
            },
            {
                "op": "add",
                "path": "/fields/Custom.ImpactingStakeholders",
                "from": null,
                "value": this.impactingStakeholders
            },
            {
                "op": "add",
                "path": "/fields/Custom.RequesterName",
                "from": null,
                "value": this.requestorName
            },
            {
                "op": "add",
                "path": "/fields/Custom.Requestor",
                "from": null,
                "value": this.requestorEmail
            },
            {
                "op": "add",
                "path": "/fields/Custom.BusinessSponsor",
                "from": null,
                "value": this.businessSponsor
            },
            {
                "op": "add",
                "path": "/fields/Custom.FundingAgencyorSource",
                "from": null,
                "value": this.fundingAgencySource
            },
            {
                "op": "add",
                "path": "/fields/Custom.SuccessCriteria",
                "from": null,
                "value": this.successCriteria
            },
            {
                "op": "add",
                "path": "/fields/Custom.HighLevelOverview",
                "from": null,
                "value": this.highLevelOverview
            },
            {
                "op": "add",
                "path": "/fields/Custom.Businessproblemstatement",
                "from": null,
                "value": this.businessProblemStatment
            },
            {
                "op": "add",
                "path": "/fields/Custom.DataRequirements",
                "from": null,
                "value": this.dataRequirements
            },
            {
                "op": "add",
                "path": "/fields/Microsoft.VSTS.CMMI.Purpose",
                "from": null,
                "value": this.purpose
            },
            {
                "op": "add",
                "path": "/fields/Custom.ValueDelivered",
                "from": null,
                "value": this.valueDelivered
            },
            {
                "op": "add",
                "path": "/fields/Custom.SubjectMatterExpert",
                "from": null,
                "value": this.SME
            }
            ];
            
              //  console.log("obj: ",obj );
                var jsonObj = JSON.stringify(obj);
                console.log("jsonObj: ",jsonObj );

                const url =
                  'https://dev.azure.com/SOC-OIT/3fb0f870-d92c-40c8-95fb-05c4512a884f/_apis/wit/workitems/$Product%20Backlog%20Item?api-version=5.1';
        
                const headers = {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': 'Basic OnU0YXlvcTczaDZqdGZ4M3ozZXpldGRuNjc1czR2bzNpY2p6dzNneGlraHN5enJrYm0yNHE='

                }

                axios.post(url, objStr, {
                        headers
                    })
                    .then(response => {
                        console.log("response.data.id: ", response.data.id);
                        console.log("response.data: ", response.data);
                        console.log("response: ", response);

                       // event.preventDefault();

                        this.timeOutMessage(response.data.id); 
                        // this.redirectTimeOut();
                        // event.preventDefault();
                        this.successMsg = response.data.id;
                        // this.timeOutMessage(); 
                        // this.redirectTimeOut();
                    })
                    // .catch(e => {
                    //     console.log("Api post failed!");
                    //     console.log("e:", e);
                    //     event.preventDefault();
                    // });

            }
    }
};
</script>