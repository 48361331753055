<template>
<div class="container">
    <div ref="elRef" class="row"></div>
    <div class="row">
        <!-- <h1>Response</h1> -->
        <div id="response">
            <form id="response-form" @submit.prevent="onSubmit">
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Project Name</label>
                        <input type="text" v-model="projectName" class="form-control" required readonly/>
                        <span v-if="msg.projectName" class="redText">{{msg.projectName}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Description</label>
                        <input type="text" v-model="description" class="form-control" readonly>
                        <span v-if='msg.description' class='redText'>{{msg.description}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Estimated Response By</label>
                        <input type="date" v-model="estimatedResponseBy" class="form-control" readonly>
                        <span v-if="msg.estimatedResponseBy" class="redText">{{msg.estimatedResponseBy}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Requested Date</label>
                        <input type="date" v-model="requestedDate" class="form-control" readonly>
                        <span v-if="msg.requestedDate" class="redText">{{msg.requestedDate}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <div class="col">
                            <div class="form-group">
                            <label class="form-label">Proposed RollOut Type</label>
                                <select v-model.number="proposedRollOutType" id="proposedRolloutTypeDropdown" class="form-select form-control" :disabled="isDisabled">
                                    <!-- <option selected disabled value="">Select from dropdown...</option> -->
                                    <option v-for="option in proposedRollOutTypeList" :key="option.value" :value="option.value">
                                        {{ option.text}}
                                    </option>
                                </select>
                                <span v-if="msg.proposedRollOutType" class="redText">{{msg.proposedRollOutType}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <label class="form-label">Estimated Delivery</label>
                        <input type="date" v-model="estimatedDelivery" class="form-control" readonly>
                        <span v-if="msg.estimatedDelivery" class="redText">{{msg.estimatedDelivery}}</span>
                    </div>                
                </div>
                <div class="mb-3 row">
                    <div class='col'>
                        <div class="form-group">
                            <label class="form-label">Anticipated Solution Options</label>
                            <select v-model="anticipatedSolutionOptionsDropdown" id="anticipatedSolutionOptionsDropdown"  class='form-select form-control' :disabled="isDisabled">
                                <option v-for="option in anticipatedSolutionDropdownList" :key="option.id" v-bind:value="option.value">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <label class="form-label">Success Criteria:</label>
                        <textarea class="form-control" v-model="successCriteria" readonly></textarea>
                        <span v-if="msg.successCriteria" class="redText">{{msg.successCriteria}}</span>
                    </div> 
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Business Product Owner</label>
                        <input type="text" v-model="businessProductOwner" class="form-control" readonly>
                        <span v-if="msg.businessProductOwner" class="redText">{{msg.businessProductOwner}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Agency</label>
                        <select v-model.number="agencyDropdown" id='agencyDropdown' class="form-select form-control" :disabled="isDisabled">
                            <!-- <option selected disabled value="">Select from dropdown...</option> -->
                            <option v-for="option in agencyDropdownList" :key="option.id" v-bind:value="option.value">
                                {{ option.text}}
                            </option>
                        </select>
                        <span v-if="msg.agency" class="redText">{{msg.agency}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Funding Agency or Source</label>
                        <input type="text" v-model="fundingAgencySource" class="form-control" readonly>
                        <span v-if="msg.fundingAgencySource" class="redText">{{msg.fundingAgencySource}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Business Sponsor</label>
                        <input type="text" v-model="businessSponsor" class="form-control" readonly>
                        <span v-if="msg.businessSponsor" class="redText">{{msg.businessSponsor}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Requester Name</label>
                        <input type="text" v-model="requestorName" class="form-control" readonly>
                        <span v-if="msg.requestorName" class="redText">{{msg.requestorName}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Requester Email</label>
                        <input type="text"  v-model="requestorEmail" class="form-control" readonly>
                        <span v-if="msg.requestorEmail" class="redText">{{msg.requestorEmail}}</span>
                    </div> 
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Other Impacted Agencies/Divisions (stakeholders)</label>
                        <textarea  v-model="otherImpactedAgency" class="form-control" readonly />
                        <span v-if="msg.otherImpactedAgency" class="redText">{{msg.otherImpactedAgency}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">High Level Overview</label>
                        <textarea v-model="highLevelOverview" class="form-control" readonly></textarea>
                        <span v-if="msg.highLevelOverview" class="redText">{{msg.highLevelOverview}}</span>
                    </div>
                    <!-- <div class="col">
                        <label class="form-label">Impacting Stakeholders</label>
                        <textarea v-model="impactingStakeholders" class="form-control" readonly></textarea>
                        <span v-if="msg.impactingStakeholders" class="redText">{{msg.impactingStakeholders}}</span>
                    </div> -->
                </div>

                <div class="">
                    <div class="">
                        <check-box fieldId="Luther" v-model:names="names" value="Luther" />
                        <check-box fieldId="Diego" v-model:names="names" value="Diego" />
                        <check-box fieldId="Allison" v-model:names="names" value="Allison" />
                        <check-box fieldId="Klaus" v-model:names="names" value="Klaus" />
                        <check-box fieldId="Five" v-model:names="names" value="Five" />
                        <check-box fieldId="Ben" v-model:names="names" value="Ben" />
                        <check-box fieldId="Vanya" v-model:names="names" value="Vanya" />
                    </div>
                    <div class="">{{ names }}</div>
                </div>                
                <div class='checkbox-section'>
                    <label class="checkbox-label">Justification/Value Delivered:</label>
                    <div class='form-control'>
                        <div class="mb-3 row checkrow">
                            <div class="col check">
                                <label class="form-label">Ease of Use</label>
                                <input v-model="checkboxes" :value="1" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                            <div class="col check">
                                <label class="form-label">Simplification</label>
                                <input v-model="checkboxes" :value="2" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div> 
                            <div class="col check">
                                <label class="form-label">Response Time</label>
                                <input v-model="checkboxes" :value="3" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                            <div class="col check">
                                <label class="form-label">Saves Time</label>
                                <input v-model="checkboxes" :value="4" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                        </div>
                        <div class="mb-3 row checkrow">
                            <div class="col check">
                                <label class="form-label">Training</label>
                                <input v-model="checkboxes" :value="5" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                            <div class="col check">
                                <label class="form-label">Compliance</label>
                                <input v-model="checkboxes" :value="6" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div> 
                            <div class="col check">
                                <label class="form-label">Data Management</label>
                                <input v-model="checkboxes" :value="7" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                            <div class="col check">
                                <label class="form-label">Pain Point</label>
                                <input v-model="checkboxes" :value="8" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                        </div>
                        <div class="mb-3 row checkrow">
                            <div class="col check">
                                <label class="form-label">Security Risk</label>
                                <input v-model="checkboxes" :value="9" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                            <div class="col check">
                                <label class="form-label">Cost Reduction</label>
                                <input v-model="checkboxes" :value="10" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                            <div class="col check">
                                <label class="form-label">Automation</label>
                                <input v-model="checkboxes" :value="11" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>
                            <div class="col check">
                                <label class="form-label">Remain</label>
                                <input v-model="checkboxes" :value="12" type="checkbox" class="checkbox" :disabled="isDisabled">
                            </div>   
                        </div>
                    </div>
                    <span v-if="msg.checkboxes" class="redText">{{msg.checkboxes}}</span>
                </div>
                <span v-if="msg.checkboxes" class="redText">{{msg.checkboxes}}</span>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Business Problem Statement</label>
                        <textarea v-model="businessProblemStatment" class="form-control" readonly></textarea>
                        <span v-if="msg.businessProblemStatment" class="redText">{{msg.businessProblemStatment}}</span>
                    </div>
                    <div class="col">
                        <label class="form-label">Data Requirements</label>
                        <textarea v-model="dataRequirements" class="form-control" readonly></textarea>
                        <span v-if="msg.dataRequirements" class="redText">{{msg.dataRequirements}}</span>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col">
                        <label class="form-label">Reporting Needs</label>
                        <textarea  v-model="reportingNeeds" class="form-control" readonly></textarea>
                        <span v-if="msg.reportingNeeds" class="redText">{{msg.reportingNeeds}}</span>
                    </div>  
                     <div class="col">
                        <label class="form-label">File Upload</label>
                        <input v-model="fileUpload" class="form-control" readonly>
                        <div>(pdf, word, google sheet, txt, html, pictures)</div>
                        <span v-if="msg.fileUpload" class="redText">{{msg.fileUpload}}</span>
                    </div>
                </div>
                

                <div class="mb-3 row" id="btn-row">
                    <div class="col">
                        <input type="button" value="Submit" @click="validateEverything" class="btn btn-primary">
                        <input type="button" value="Cancel" class="btn btn-secondary">
                    </div>
                </div>
                <div class="row">
                    <transition name="fade">
                        <p v-if="showSuccess" class="success" style="float:top; background-color:green;padding:10px;color:white;">Your project was submitted successfully. {{this.successMsg}} is the azure id associated with your submission.</p>
                    </transition>
                </div>
            </form>
        </div>
    </div>
</div>

</template>

<script>
      //import axios from 'axios'

    export default{
        data() {
            return{
                projectName: dataArray.projectName,
                estimatedResponseBy: dataArray.estimatedResponseBy,
                proposedRollOutType: dataArray.proposedRollOutType,
                requestedDate: dataArray.requestedDate,
                anticipatedSolutionOptionsDropdown: dataArray.anticipatedSolutionOptionsDropdown,
                estimatedDelivery: dataArray.estimatedDelivery,
                successCriteria: dataArray.successCriteria,
                description: dataArray.description,
                fileUpload: dataArray.fileUpload,
                businessProductOwner: dataArray.businessProductOwner,
                fundingAgencySource: dataArray.fundingAgencySource,
                businessSponsor: dataArray.businessSponsor,
                requestorName: dataArray.requestorName,
                requestorEmail: dataArray.requestorEmail,
                otherImpactedAgency: dataArray.otherImpactedAgency,
                impactingStakeholders: dataArray.impactingStakeholders,
                highLevelOverview: dataArray.highLevelOverview,
                businessProblemStatment: dataArray.businessProblemStatment,
                dataRequirements: dataArray.dataRequirements,
                reportingNeeds: dataArray.reportingNeeds,
                agencyDropdown: dataArray.agencyDropdown,
                showSuccess: false,
                successMsg: '',
                SME: '',
                productOwnerPercentAllocation: '',
                smePercentAllocation: '',
                valueDelivered: '',
                requestedRespondBy: '',
                purpose: 'true purpose',
                checkboxes: dataArray.checkboxes,
                errors: [],
                valPass: true,
                msg: {
                    projectName: '',
                    responseBy: '',
                    proposedRollOutType: '',
                    requestedDate: '',
                    anticipatedSolutionOptions: '',
                    estimatedDelivery: '',
                    successCriteria: '',
                    description: '',
                    fileUpload: '',
                    businessProductOwner: '',
                    fundingAgencySource: '',
                    businessSponsor: '',
                    requestorName: '',
                    requestorEmail: '',
                    otherImpactedAgency: '',
                    impactingStakeholders: '',
                    highLevelOverview: '',
                    businessProblemStatment: '',
                    dataRequirements: '',
                    reportingNeeds: '',
                    agency: ''
                },
                isDisabled: true,
                proposedRollOutTypeList: [
                    { text: 'Big-bang', value: 1 },
                    { text: 'Phased', value: 2 }
                ],
                anticipatedSolutionDropdownList: [ // 1 = value passed in response.data
                    { text: 'modification or enhancement to existing business application', value: 1 },
                    { text: 'upgrade to a new release', value: 2 },
                    { text: 'new application', value: 3 }
                ],
                agencyDropdownList: [
                    { text: 'Department of Agriculture (CDA)', value: 1 },
                    { text: 'Department of Corrections (DOC)', value: 2 },
                    { text: 'Department of Education (DOE)', value: 3 },
                    { text: 'Department of Health Care Policy & Financing (HCPF)', value: 4 },
                    { text: 'Department of Human Services (CDHS)', value: 5 },
                    { text: 'Department of Labor and Employment (CDLE)', value: 6 },
                    { text: 'Department of Law (DOL)', value: 7 },
                    { text: 'Department of Local Affairs (DOLA)', value: 8 },
                    { text: 'Department of Military and Veterans Affairs (DMVA)', value: 9 },
                    { text: 'Department of Natural Resources (DNR)', value: 10 },
                    { text: 'Department of Personnal & Administration (DPA)', value: 11 },
                    { text: 'Department of Public Health & Environment (CDPHE)', value: 12 },
                    { text: 'Department of Public Safety (CDPS)', value: 13 },
                    { text: 'Department of Regulatory Agencies (DORA)', value: 14 },
                    { text: 'Department of Revenue (CDOR)', value: 15 },
                    { text: 'Department of State (DOS)', value: 16 },
                    { text: 'Department of Transportation (CDOT)', value: 17 },
                    { text: "Governor's Office of Information Technology", value: 18 }
                ]
            };
        },
        methods: {}
    }

    var dataArray = {
        projectName: 'project name HARDCODE',
        estimatedResponseBy: '2021-10-28',
        description: 'description HARDCODE',
        proposedRollOutType: 2,
        requestedDate: '2021-10-14',
        anticipatedSolutionOptionsDropdown: 2,
        estimatedDelivery: '2021-10-27',    // T00:00:00Z
        successCriteria: 'success criteria HARDCODE',
        fileUpload: 'file upload HARDCODE',
        businessProductOwner: 'business product owner HARDCODE',
        fundingAgencySource: 'funding agency source HARDCODE',
        businessSponsor: 'business sponsor HARDCODE',
        requestorName: 'requestor name HARDCODE',
        requestorEmail: 'requestor email HARDCODE',
        otherImpactedAgency: 'other impacted agency HARDCODE',
        impactingStakeholders: 'impacting stakeholders HARDCODE',
        highLevelOverview: 'high level overview HARDCODE',
        businessProblemStatment: 'business problem statement HARDCODE',
        dataRequirements: 'data requirements HARDCODE',
        reportingNeeds: 'reporting needs HARDCODE',
        agencyDropdown: 1,
        checkboxes: [3,4],
        showSuccess: false,
        successMsg: '',
        SME: '',
        productOwnerPercentAllocation: '',
        smePercentAllocation: '',
        valueDelivered: '',
        requestedRespondBy: '',
        purpose: 'true purpose',
    }

    // DropdownSelection(dataArray.anticipatedSolutionOptionsDropdown, dataArray.agencyDropdown) {
    //     for(let i = 1;i<anticipatedSolutionOptionsDropdown.Length;i++){
    //         if(dataArray.anticipatedSolutionOptionsDropdown = i){
                
    //         }
    //     }
    // }

    console.log("dataArray = ",dataArray);
    console.log("dataArray.anticipatedSolutionOptionsDropdown = " + JSON.stringify(dataArray.anticipatedSolutionOptionsDropdown));
    // console.log("anticipated solutions options drowndown List = " + this.anticipatedSolutionOptionsDropdownList);
    // console.log("agency dropdown list = " + this.agencyDropdownList)

    
    // }
</script>
